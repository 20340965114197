<template>
  <div class="head-container" style="float:right;">
    <!-- 搜索 -->
    <el-select v-model="time"  placeholder="统计区间" class="filter-item" style="width: 200px"  @change="toQuery">
       <el-option v-for="item in times" :key="item.id" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-button v-if="checkPermission(['ADMIN','TRADEORDER_ALL','TRADEORDER_SELECT'])" class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      time:'1',
      times: [
        { key: '1', display_name: '近一小时' },
        { key: '2', display_name: '近一天' },
        { key: '3', display_name: '近一周'},
        { key: '4', display_name: '近一个月' }
      ],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.query.time=this.time
      this.$parent.page = 0
      this.$parent.init()
    },
  }
}
</script>
