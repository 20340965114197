import request from '@/utils/request'

export function subComplaint(params) {
    return request({
        url: 'community/crm/srComplaintSerial',
        method: 'get',
        params
    })
}
export function SubSerial(params) {
    return request({
        url: 'community/crm/srSubSerial',
        method: 'get',
        params
    })
}
export function Handle(id) {
    return request({
        url: 'community/crm/srComplaint/'+id,
        method: 'put'
    })
}
