<template>
  <el-dialog
    :append-to-body="true"
             :visible="dialogShow"
    :before-close="beforeClose"
    title="失败订单统计"
    width="820px">
      <!--表格渲染-->
      <el-table :data="data" border stripe style="width: 100%;">
        <el-table-column prop="platformName" label="经纪商平台" />
        <el-table-column prop="login" label="MT4账户"/>
        <el-table-column prop="failOpenCount" label="开仓失败订单数" />
        <el-table-column prop="failCloseCount" label="平仓失败订单数" />
        <el-table-column prop="operateTime" label="操作" align="center">
          <template slot-scope="scope">
            <span style="cursor:pointer;color:#409EFF" @click="lookHandle(scope.row)">查看</span>
          </template>
        </el-table-column>
      </el-table>
      <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </el-dialog>
</template>

<script>
import { parseTime } from '@/utils/index'
import { Handle } from '@/api/community/srCommunitySubSerial.js'
import request from '@/utils/request'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
export default {
  props: {
    dialogShow:{
      default: false,
    }
  },
  mixins: [initData],
  data(){
    return {
      failDataPlatformName:'',
      startTime:'',
      endTime:''
    }
  },
  methods: {
    checkPermission,
    parseTime,
    beforeInit() {
      this.url = '/community/crm/queryMissOrderDetail'
      this.params = {
        page: this.page,
        size: this.size,
        startTime:this.startTime,
        endTime:this.endTime,
        platformName:this.failDataPlatformName
      }

      return true
    },
    lookHandle(row){
      this.$router.push({
        path: "/commu/subscription/copyquery",
        query: {
          login: row.login,
          startTime:this.startTime,
          endTime:this.endTime
        }
      });
    },
    beforeClose(){
      this.$emit('close-dialog');
    }
  }
}
</script>

<style lang="less">
  .cashdialog {
    .title {
      background: #ccc;
      padding: 10px 20px;
      color: #333;
      font-size: 20px;
      font-weight: 800;
    }
    .info {
      margin: 10px;
      .el-col {
        padding: 15px 20px;
        .th {
          font-size: 16px;
          font-weight: 800;
          padding: 10px 10px;
        }
        .td {

        }
      }
    }
    .opinion {
      display: flex;
      align-items: center;
      .paylink {
         display: flex;
         height: 52px;
         align-items: flex-start;
      }
    }
  }
</style>
