<template>
  <div class="app-container">
    <eHeader :query="query" />
    <!--表格渲染-->
    <el-table v-loading="loading" border :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="platformName" label="经纪商平台"/>
      <el-table-column prop="succOpenRate" label="开仓成功率"/>
      <el-table-column prop="failOpenRate" label="开仓失败率"/>
      <el-table-column prop="succCloseRate" label="平仓成功率"/>
      <el-table-column prop="failCloseRate" label="平仓失败率"/>
      <el-table-column prop="missRate" label="漏单率"/>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <el-button v-if="(scope.row.failOpenRate!='0.00%' || scope.row.failCloseRate!='0.00%')&& checkPermission(['ADMIN','TRADEORDER_ALL','TRADEORDER_SELECT'])"  size="small" @click="failHandle(scope.row)">失败订单统计</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <failDetail
      ref="failDetail"
      :dialogShow="failDetailDialogShow"
      @close-dialog="closeDialogHandel"
    ></failDetail>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import { parseTime } from '@/utils/index'
import initData from "@/mixins/initData";
import { getSitesData } from '@/api/cms/srSite'
import eHeader from '@/components/community/subscription/statistics/header'
import failDetail from '@/components/community/subscription/statistics/failDetail'
export default {
  name:'statistics',
  components: { eHeader, failDetail },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      failDataPlatformName:'',
      failDataType:'',
      failDetailDialogShow: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/community/crm/queryCopyStatistics'
      this.params = {
        page: this.page,
        size: this.size
      }
      const query = this.query;
      if(query.time!=''&&query.time!=null) {
        this.params.type=query.time;
      }else {
        this.params.type='1';
      }

      return true
    },
    toClearInit() {
      this.query = {}
      this.init()
    },
    failHandle (row){
       this.$refs['failDetail'].startTime=row.startTime
       this.$refs['failDetail'].endTime=row.endTime
       this.$refs['failDetail'].failDataPlatformName=row.platformName
       this.$refs['failDetail'].init()
      this.failDetailDialogShow = true;

    },
    closeDialogHandel(){
      this.failDetailDialogShow = false;
    }
  }
}
</script>

<style scoped>
.link-sum{
  color:blue;
  text-decoration:underline;
  cursor:pointer;
}
.link-sum:hover {
  text-decoration:underline;
   color:red;
}
</style>
